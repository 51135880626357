<template>
  <div class="vg_wrapper">
    <add-header
        @submit="submit('cpttForm')"
        @cancel="cancel"
    ></add-header>
    <el-form ref="cpttForm" :model="cpttForm" :rules="rules" label-width="120px" size="mini">
      <el-row :gutter="24">
        <el-col :md="8">
          <el-form-item label="公司编号" prop="cptt_no">
            <el-input v-model="cpttForm.cptt_no" maxlength="10" @input ="cpttForm.cptt_no = helper.keepEngNum2(cpttForm.cptt_no)" show-word-limit placeholder="请填写公司编号（仅支持数字和英文）"></el-input>
          </el-form-item>
          <el-form-item label="公司简称" prop="cptt_abbr">
            <el-input v-model="cpttForm.cptt_abbr" maxlength="10" show-word-limit  placeholder="请填写公司简称"></el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="公司抬头" prop="cptt_name">
            <el-input v-model="cpttForm.cptt_name" maxlength="50" show-word-limit placeholder="请填写公司抬头"></el-input>
          </el-form-item>
          <el-form-item label="公司地址" prop="cptt_address">
            <el-input  type="textarea" :autosize='{ minRows:2, maxRows: 6 }'  v-model="cpttForm.cptt_address" placeholder="请填写公司地址" maxlength="100" show-word-limit></el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="公司类型" prop="cptt_type">
            <el-select v-model="cpttForm.cptt_type" placeholder="请选择公司类型" clearable>
              <el-option
                  v-for="item in cpttTypeGroupOpt"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="公司电话" prop="cptt_phone">
            <el-input v-model="cpttForm.cptt_phone" maxlength="100" type="textarea" :autosize='{ minRows:2, maxRows: 6 }' show-word-limit placeholder="请填写公司电话"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <!-- <el-divider content-position="center" class="vd_div" v-if="isAcct">凡爱专属管理</el-divider> -->
      <!-- <el-row :gutter="24" v-if="isAcct">
        <el-col :md="8">
          <el-form-item  label="所属账套">
            <el-select @change="selectChange" v-model="cpttForm.acct_id" placeholder="请选择账套名称" clearable @visible-change="getAcct($event)">
              <el-option
                  v-for="item in acctOpt"
                  :key="item.acct_id"
                  :label="item.acct_no"
                  :value="item.acct_id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item  label="捆绑账套">
            <el-select v-model="cpttForm.cptt_acct_id" placeholder="请选择账套名称" clearable   @change="changeAcct">
              <el-option
                  v-for="item in acctOpt"
                  :key="item.acct_id"
                  :label="item.acct_no"
                  :value="item.acct_id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item  label="捆绑账套公司">
            <el-select v-model="cpttForm.cptt_acct_fctr_id" placeholder="暂无捆绑账套（可添加）" clearable>
              <el-option
                  v-for="item in acctFctr"
                  :key="item.acct_fctr_id"
                  :label="item.acct_fctr_no"
                  :value="item.acct_fctr_id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row> -->
      <!-- <el-row :gutter="24" class="vd_rang " v-if='isAcct'>
        <el-col class="vd_elform" :md="18">
          <el-form-item  label="分享账套"> 
            <el-button class="vd_mrf20" type="primary" plain size="mini" @click="addType()">添加</el-button>
          </el-form-item>
          <el-table :data="cpttForm.cptt_acct_list" border v-if="flag">
            <el-table-column label="账套编号" width="150px">
              <template slot-scope="scope">
                <el-form-item label-width="0"  :prop="'cptt_acct_list.' + scope.$index + '.acct_id'"  required>
                  <el-select @change="acctSelect(scope)" v-model="cpttForm.cptt_acct_list[scope.$index].acct_id" placeholder="请选择账套" clearable>
                    <el-option
                      v-for="item in acctCopy"
                      :key="item.acct_id"
                      :label="item.acct_no"
                      :value="item.acct_id"
                      :disabled="item.disabled">
                    </el-option>
                  </el-select>
                </el-form-item>
              </template>
            </el-table-column>
             <el-table-column label='账套名称' >
              <template slot-scope="scope">
                  <span>{{scope.row | filterAcct(acctCopy)}}</span> 
              </template>
              </el-table-column>
            <el-table-column label="所属账套" align='center'>
              <template slot-scope="scope">
                 <el-checkbox class="radio" @change="radioChange(scope)" v-model="scope.row.checked"  :disabled='!scope.row.acct_id'>
                 </el-checkbox>
              </template>
            </el-table-column>
           
            <el-table-column align="center" width="150px">
              <template slot-scope="scope">
                <el-link type="danger" class="vg_pointer" v-if="cpttForm.cptt_acct_list[scope.$index].cptt_acct_share === 0"  @click="delType(scope)">移除</el-link>
                <el-link type="primary" v-else :disabled="isShow">系统</el-link>
              </template>
            </el-table-column>
            
          </el-table>
        </el-col>
      </el-row> -->
      <el-divider></el-divider>
      <el-row class="vg_mt_16">
        <el-col>
          <inputUser :isCookie="true" ref="userData"></inputUser>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import {get,post} from "@api/request";
import {cpttAPI} from "@api/modules/comptitle";
import {acctAPI} from "@api/modules/acct";
import inputUser from "@/views/component/inputUser";
import addHeader from "@/views/component/addHeader";
import options from "@/views/component/common/options";
export default {
  name: 'CpttAddMain',
  components: {
    inputUser,
    addHeader
  },
  data() {
    return {
      cpttForm: {
        cptt_no: '',
        cptt_name: '',
        cptt_type: null,
        cptt_address: '',
        cptt_phone: '',
        cptt_acct_id:null,
        acct_id:null,
        cptt_acct_fctr_id:null,
        key: 0,
        status: 0,
        cptt_acct_list:[],
        cptt_abbr:''
      },
      isAcct:false,
      cpttTypeGroupOpt:[],
      acctOpt:[],
      acctCopy:[],
      acctFctr:[],
      value:'',
      flag:true,
      shareType:[{
        shareId:0,
        shareName:'主动',
      },{
        shareId:1,
        shareName:'被动',
      }],
      rules:{
        cptt_no:[
          { required: true, trigger: 'blur' },
        ],
        cptt_name:[
          { required: true, trigger: 'blur' },
        ],
        cptt_type:[
          { required: true, trigger: 'change' , message: ' ' },
        ],
        cptt_abbr:[
           { required: true, trigger: 'blur',message:' '  }
        ]
      }
    }
  },
  watch: {
  },
  created() {
    this.initData()
    this.cpttTypeGroupOpt = options.cpttOpts
    if(this.$cookies.get('userInfo').acct_id === 1){
      this.isAcct = true
    }
  },
  methods: {
    getAcct(flag){
      if(flag === true && this.acctOpt.length === 0){
        this.getAccts()
      }
    },
    initData(){
      setTimeout(() => {
        this.getAccts()
        this.getAcctFctr()
      }, 100);
    },
    // 获取账套accts
    getAccts(){
      if(this.$cookies.get("userInfo").acct_id === 1){
      get(acctAPI.getAllAcctsV1)
          .then(res =>{
            if(res.data.code === 0){
              this.acctOpt = res.data.data;
              this.acctCopy = JSON.parse(JSON.stringify(this.acctOpt));
            }else{
              let mg = res.data.msg;
              let tp = 'error';
              this.$message({message:mg,type:tp});
            }
          })
          .catch((res)=>{
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({message:mg,type:tp});
          })
          }
    },
    // 获取账套accts
    getAcctFctr(){
      get(acctAPI.getAcctFctrs,{acct_id:this.cpttForm.cptt_acct_id})
      .then(res =>{
        if(res.data.code === 0){
          this.acctFctr = res.data.data.list;
        }else{
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({message:mg,type:tp});
        }
      })
      .catch((res)=>{
        let mg = res.data.msg;
        let tp = 'error';
        this.$message({message:mg,type:tp});
      })
    },
    //提交表单
    submit(formName){
     
      this.$confirm('确认提交?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$refs[formName].validate((valid) => {
          if (valid) {
             let flag = true 
            flag = this.cpttForm.cptt_acct_list.every(item=>{
              return item.acct_id
            })
            if(flag == false){
              return this.$message.error('请填写账套名称')
            }
           
            this.saveInfo()
          } else {
            console.log('error submit!');
            return false;
          }
        });
      }).catch(()=>{})
    },

    // 保存
    saveInfo() { 
      this.cpttForm.cptt_acct_list.forEach(item=>{
        if(item.checked){
          item.cptt_acct_salf = 1
        }
      })
      const staffForm =  this.$refs.userData.getData()
      this.cpttForm = Object.assign(staffForm,this.cpttForm)
      post(cpttAPI.addCptt,this.cpttForm)
          .then(res => {
            if(res.data.code === 0){
              this.$message({
                type:'success',
                message:'保存成功'
              })
              const permId = this.$route.query.perm_id
              this.jump(`/cptt_edit?perm_id=${permId}&form_id=${res.data.data.form_id}`)
              this.cpttForm.cptt_acct_list = []
              this.resetForm('cpttForm')
            }else if(res.data.code === 7){
              this.$message({
                type:'error',
                message:'公司编号重复'
              })
            } else {
              
              let mg = res.data.msg;
              let tp = 'error';
              this.$message({message: mg, type: tp});
            }
          }).catch(res =>{
               let mg = res.data.msg;
              let tp = 'error';
              this.$message({message: mg, type: tp});
          })
    },
    // 特殊数据范围添加
    addType() {
      let item = {
        acct_id:null,
        cptt_acct_share:0,
        cptt_acct_salf:0,
        checked:false
      };
      this.cpttForm.cptt_acct_list.push(item)
    },
    // 特殊数据范围添加删除
    delType(scope){
      this.cpttForm.cptt_acct_list.splice(scope.$index,1)
    },
    // 选择账套重复判断
    acctSelect(val){
      if(val.row.acct_id !== null){
        for(let i=0;i<this.cpttForm.cptt_acct_list.length;i++){
          if(i !== val.$index){
            if(this.cpttForm.cptt_acct_list[i].acct_id === val.row.acct_id){
              val.row.acct_id = null;
              this.$message({
                type:'warning',
                message:'账套选择重复'
              })
              return 
            }
          }
        }
      }
    },
    //取消
    cancel(){
      this.$confirm('确定取消新增?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      }).then(() => {
        this.resetForm('cpttForm')
        this.$router.go(-1)
        this.$message({
          type: 'info',
          message: '已取消新增!'
        });
      }).catch(()=>{})
    },
    // 选择框发生变化的时候
    // selectChange(val){
    //   for(let i=0;i<this.acctCopy.length;i++){
    //     if(this.acctCopy[i].acct_id === val){
    //       this.flag = false;
    //       this.acctCopy[i].disabled = true;
    //       this.$nextTick(()=>{
    //         this.flag = true;
    //       })
    //     }else{
    //       this.acctCopy[i].disabled = false;
    //     }
    //   }
    // },
    //捆绑账套框下拉时发生改变
    // changeAcct(){
    //   this.getAcctFctr()
    //   this.cpttForm.cptt_acct_fctr_id = ''
    // },
    //表单重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.acct_id = null;
      this.cptt_acct_id = null;
      this.cptt_acct_fctr_id = null;
    },
    radioChange(scope){
      let flag = scope.row.checked 
      if(flag){
        this.cpttForm.cptt_acct_list.forEach((item,index) => {
          if(index!==scope.$index){
            this.cpttForm.cptt_acct_list[index].checked = false;
          }
      
      });
      }else{
        this.cpttForm.cptt_acct_list.forEach((item,index) => {
            this.cpttForm.cptt_acct_list[index].checked = false;
      });
      }
    }
  }, 
  filters: {
   filterAcct(row,acctCopy){
     let str = ''
     acctCopy.forEach(item =>{
       if(item.acct_id==row.acct_id){
         str =  item.acct_name
       }
     })
     return str
   }
  }
};
</script>

<style scoped lang="scss">
.vg_wrapper ::v-deep.el-form-item{
  margin: 0;
  padding: 0;
}
.vg_wrapper ::v-deep .el-table td,::v-deep.el-table th{
  padding: 0;
}

.vg_wrapper ::v-deep .el-table .cell {
  padding: 0;
}

.vd_div {
  padding:2px;
  ::v-deep.el-divider__text{
    color: #fff;
    background-color: #95a9d8;
  }
}
.vd_rang{
  margin-top: 20px;
}
.vd_elform{
  display: flex;
}
.vd_mrf20{
margin-right: 20px;
}  
.radio {
  margin-right: 0;
}
::v-deep .el-radio__label {
  visibility: hidden;
  margin: 0;
  padding: 0;
  // opacity: 0;
}
</style>